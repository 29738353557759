@font-face {
    font-family: 'iconfont';
    src: url('fonts/iconfont.eot?ag5ule');
    src: url('fonts/iconfont.eot?ag5ule#iefix') format('embedded-opentype'),
        url('fonts/iconfont.ttf?ag5ule') format('truetype'),
        url('fonts/iconfont.woff?ag5ule') format('woff'),
        url('fonts/iconfont.svg?ag5ule#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tickets1:before {
    content: '\e91e';
}
.icon-default-avatar:before {
    content: '\e994';
}
.icon-fees:before {
    content: '\e91d';
}
.icon-star-empty:before {
    content: '\e910';
}
.icon-star-filled:before {
    content: '\e911';
}
.icon-plus:before {
    content: '\e912';
}
.icon-advantages-1:before {
    content: '\e90c';
}
.icon-advantages-2:before {
    content: '\e90d';
}
.icon--advantages-3:before {
    content: '\e90e';
}
.icon-advantages-4:before {
    content: '\e90f';
}
.icon-mastercard:before {
    content: '\e900';
}
.icon-visa:before {
    content: '\e901';
}
.icon-viber:before {
    content: '\e913';
}
.icon-fb:before {
    content: '\e914';
}
.icon-linkedin:before {
    content: '\e915';
}
.icon-skype:before {
    content: '\e916';
}
.icon-instagram:before {
    content: '\e91b';
}
.icon-whatsapp:before {
    content: '\e917';
}
.icon-telegram:before {
    content: '\e91f';
}
.icon-twitter:before {
    content: '\e918';
}
.icon-address:before {
    content: '\e902';
}
.icon-chat:before {
    content: '\e903';
}
.icon-email:before {
    content: '\e904';
}
.icon-license:before {
    content: '\e905';
}
.icon-telephone:before {
    content: '\e906';
}
.icon-arrow-left:before {
    content: '\e907';
}
.icon-arrow-right:before {
    content: '\e908';
}
.icon-arrow-up:before {
    content: '\e909';
}
.icon-arrow-down:before {
    content: '\e919';
}
.icon-listing-arrow:before {
    content: '\e90a';
}
.icon-check-mark:before {
    content: '\e90b';
}
.icon-find:before {
    content: '\e91a';
}
.icon-copy:before {
    content: '\e9c0';
}
.icon-tickets:before {
    content: '\e91c';
}
