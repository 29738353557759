@font-face {
    font-family: 'iconfont';
    src: url('fonts/iconfont.eot?fhc7eg');
    src: url('fonts/iconfont.eot?fhc7eg#iefix') format('embedded-opentype'),
        url('fonts/iconfont.ttf?fhc7eg') format('truetype'),
        url('fonts/iconfont.woff?fhc7eg') format('woff'),
        url('fonts/iconfont.svg?fhc7eg#iconfont') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'iconfont' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-czk:before {
    content: '\e968';
}
.icon-aed:before {
    content: '\e96b';
}
.icon-bgn:before {
    content: '\e96c';
}
.icon-bhd:before {
    content: '\e96d';
}
.icon-cnh:before {
    content: '\e96e';
}
.icon-dkk:before {
    content: '\e96f';
}
.icon-hkd:before {
    content: '\e970';
}
.icon-hrk:before {
    content: '\e971';
}
.icon-huf:before {
    content: '\e972';
}
.icon-ils:before {
    content: '\e973';
}
.icon-inr:before {
    content: '\e976';
}
.icon-isk:before {
    content: '\e97c';
}
.icon-jod:before {
    content: '\e97d';
}
.icon-krw:before {
    content: '\e97e';
}
.icon-kwd:before {
    content: '\e97f';
}
.icon-mad:before {
    content: '\e980';
}
.icon-nok:before {
    content: '\e981';
}
.icon-nzd:before {
    content: '\e982';
}
.icon-omr:before {
    content: '\e983';
}
.icon-pln:before {
    content: '\e984';
}
.icon-qar:before {
    content: '\e985';
}
.icon-ron:before {
    content: '\e986';
}
.icon-rsd:before {
    content: '\e987';
}
.icon-sar:before {
    content: '\e988';
}
.icon-sek:before {
    content: '\e989';
}
.icon-sgd:before {
    content: '\e98a';
}
.icon-tnd:before {
    content: '\e98b';
}
.icon-twd:before {
    content: '\e98c';
}
.icon-aeon:before {
    content: '\e95e';
}
.icon-btt:before {
    content: '\e963';
}
.icon-pac:before {
    content: '\e9e7';
}
.icon-abt:before {
    content: '\e9e8';
}
.icon-act:before {
    content: '\e9e9';
}
.icon-ada:before {
    content: '\e9ea';
}
.icon-adc:before {
    content: '\e9eb';
}
.icon-adi:before {
    content: '\e9ec';
}
.icon-adx:before {
    content: '\e9ed';
}
.icon-ae:before {
    content: '\e9ee';
}
.icon-aet:before {
    content: '\e9ef';
}
.icon-agi:before {
    content: '\e9f0';
}
.icon-agrs:before {
    content: '\e9f1';
}
.icon-aidoc:before {
    content: '\e9f2';
}
.icon-aion:before {
    content: '\e9f3';
}
.icon-alpha:before {
    content: '\e9f4';
}
.icon-amb:before {
    content: '\e9f5';
}
.icon-amp:before {
    content: '\e9f6';
}
.icon-ant:before {
    content: '\e9f7';
}
.icon-appc:before {
    content: '\e9f8';
}
.icon-ar2:before {
    content: '\e9f9';
}
.icon-ardr:before {
    content: '\e9fa';
}
.icon-ark:before {
    content: '\e9fb';
}
.icon-arn:before {
    content: '\e9fc';
}
.icon-ary:before {
    content: '\e9fd';
}
.icon-ast:before {
    content: '\e9fe';
}
.icon-atb:before {
    content: '\e9ff';
}
.icon-atl:before {
    content: '\ea00';
}
.icon-atm:before {
    content: '\ea01';
}
.icon-chart-dropdown-arrow1:before {
    content: '\ea02';
}
.icon-arrow:before {
    content: '\ea03';
}
.icon-auto:before {
    content: '\ea04';
}
.icon-bar:before {
    content: '\ea05';
}
.icon-bar-tbar:before {
    content: '\ea06';
}
.icon-bat:before {
    content: '\ea07';
}
.icon-bat-alt:before {
    content: '\ea08';
}
.icon-bay:before {
    content: '\ea09';
}
.icon-bcc:before {
    content: '\ea0a';
}
.icon-bcd:before {
    content: '\ea0b';
}
.icon-bch:before {
    content: '\ea0c';
}
.icon-bcn:before {
    content: '\ea0d';
}
.icon-bco:before {
    content: '\ea0e';
}
.icon-bcpt:before {
    content: '\ea0f';
}
.icon-bdg:before {
    content: '\ea10';
}
.icon-bdl:before {
    content: '\ea11';
}
.icon-bec:before {
    content: '\ea12';
}
.icon-bee:before {
    content: '\ea13';
}
.icon-bela:before {
    content: '\ea14';
}
.icon-Bez:before {
    content: '\ea15';
}
.icon-bitb:before {
    content: '\ea16';
}
.icon-bix:before {
    content: '\ea17';
}
.icon-blcn:before {
    content: '\ea18';
}
.icon-blk:before {
    content: '\ea19';
}
.icon-block:before {
    content: '\ea1a';
}
.icon-blz:before {
    content: '\ea1b';
}
.icon-bm:before {
    content: '\ea1c';
}
.icon-bmc:before {
    content: '\ea1d';
}
.icon-bnb:before {
    content: '\ea1e';
}
.icon-bnt:before {
    content: '\ea1f';
}
.icon-bnty:before {
    content: '\ea20';
}
.icon-bos:before {
    content: '\ea21';
}
.icon-bpt:before {
    content: '\ea22';
}
.icon-bq:before {
    content: '\ea23';
}
.icon-brd:before {
    content: '\ea24';
}
.icon-brk:before {
    content: '\ea25';
}
.icon-bstn:before {
    content: '\ea26';
}
.icon-btcbitstamp:before {
    content: '\ea27';
}
.icon-btc:before {
    content: '\ea28';
}
.icon-btcd:before {
    content: '\ea29';
}
.icon-btcp:before {
    content: '\ea2a';
}
.icon-btcz:before {
    content: '\ea2b';
}
.icon-btg:before {
    content: '\ea2c';
}
.icon-btm:before {
    content: '\ea2d';
}
.icon-bts:before {
    content: '\ea2e';
}
.icon-btx:before {
    content: '\ea2f';
}
.icon-burst:before {
    content: '\ea30';
}
.icon-can:before {
    content: '\ea31';
}
.icon-candy:before {
    content: '\ea32';
}
.icon-capp:before {
    content: '\ea33';
}
.icon-cas:before {
    content: '\ea34';
}
.icon-ccos:before {
    content: '\ea35';
}
.icon-ccrb:before {
    content: '\ea36';
}
.icon-cdn:before {
    content: '\ea37';
}
.icon-cdt:before {
    content: '\ea38';
}
.icon-cennz:before {
    content: '\ea39';
}
.icon-chat1:before {
    content: '\ea3a';
}
.icon-chips:before {
    content: '\ea3b';
}
.icon-cix:before {
    content: '\ea3c';
}
.icon-cjt:before {
    content: '\ea3d';
}
.icon-ckey:before {
    content: '\ea3e';
}
.icon-clam:before {
    content: '\ea3f';
}
.icon-cloak:before {
    content: '\ea40';
}
.icon-clout:before {
    content: '\ea41';
}
.icon-club:before {
    content: '\ea42';
}
.icon-cmt:before {
    content: '\ea43';
}
.icon-cnd:before {
    content: '\ea44';
}
.icon-cnn:before {
    content: '\ea45';
}
.icon-cnx:before {
    content: '\ea46';
}
.icon-cny:before {
    content: '\ea47';
}
.icon-cob:before {
    content: '\ea48';
}
.icon-corex:before {
    content: '\ea49';
}
.icon-cps:before {
    content: '\ea4a';
}
.icon-cpt:before {
    content: '\ea4b';
}
.icon-crb:before {
    content: '\ea4c';
}
.icon-cred:before {
    content: '\ea4d';
}
.icon-crpt:before {
    content: '\ea4e';
}
.icon-crw:before {
    content: '\ea4f';
}
.icon-cs:before {
    content: '\ea50';
}
.icon-ctr:before {
    content: '\ea51';
}
.icon-ctx:before {
    content: '\ea52';
}
.icon-ctxt:before {
    content: '\ea53';
}
.icon-cure:before {
    content: '\ea54';
}
.icon-cv:before {
    content: '\ea55';
}
.icon-cvc:before {
    content: '\ea56';
}
.icon-cvt:before {
    content: '\ea57';
}
.icon-dash:before {
    content: '\ea58';
}
.icon-dat:before {
    content: '\ea59';
}
.icon-data:before {
    content: '\ea5a';
}
.icon-dbc:before {
    content: '\ea5b';
}
.icon-dbet:before {
    content: '\ea5c';
}
.icon-dcc:before {
    content: '\ea5d';
}
.icon-dcn:before {
    content: '\ea5e';
}
.icon-dcorp:before {
    content: '\ea5f';
}
.icon-dcr:before {
    content: '\ea60';
}
.icon-dent:before {
    content: '\ea61';
}
.icon-dew:before {
    content: '\ea62';
}
.icon-dgb:before {
    content: '\ea63';
}
.icon-dgd:before {
    content: '\ea64';
}
.icon-dgtx:before {
    content: '\ea65';
}
.icon-dlt:before {
    content: '\ea66';
}
.icon-dmt:before {
    content: '\ea67';
}
.icon-dnt:before {
    content: '\ea68';
}
.icon-doge:before {
    content: '\ea69';
}
.icon-drgn:before {
    content: '\ea6a';
}
.icon-drop:before {
    content: '\ea6b';
}
.icon-dta:before {
    content: '\ea6c';
}
.icon-dtr:before {
    content: '\ea6d';
}
.icon-ebst:before {
    content: '\ea6e';
}
.icon-ebtc:before {
    content: '\ea6f';
}
.icon-echg:before {
    content: '\ea70';
}
.icon-echt:before {
    content: '\ea71';
}
.icon-edg:before {
    content: '\ea72';
}
.icon-edo:before {
    content: '\ea73';
}
.icon-edoge:before {
    content: '\ea74';
}
.icon-edt:before {
    content: '\ea75';
}
.icon-ekt:before {
    content: '\ea76';
}
.icon-elf:before {
    content: '\ea77';
}
.icon-elix:before {
    content: '\ea78';
}
.icon-ella:before {
    content: '\ea79';
}
.icon-emc:before {
    content: '\ea7a';
}
.icon-emc2:before {
    content: '\ea7b';
}
.icon-eng:before {
    content: '\ea7c';
}
.icon-enj:before {
    content: '\ea7d';
}
.icon-enu:before {
    content: '\ea7e';
}
.icon-eos:before {
    content: '\ea7f';
}
.icon-eosdac:before {
    content: '\ea80';
}
.icon-equa:before {
    content: '\ea81';
}
.icon-etc:before {
    content: '\ea82';
}
.icon-eth:before {
    content: '\ea83';
}
.icon-ethos:before {
    content: '\ea84';
}
.icon-etn:before {
    content: '\ea85';
}
.icon-etp:before {
    content: '\ea86';
}
.icon-evc:before {
    content: '\ea87';
}
.icon-evx:before {
    content: '\ea88';
}
.icon-exmo:before {
    content: '\ea89';
}
.icon-exmr:before {
    content: '\ea8a';
}
.icon-exp:before {
    content: '\ea8b';
}
.icon-face:before {
    content: '\ea8c';
}
.icon-fair:before {
    content: '\ea8d';
}
.icon-fct:before {
    content: '\ea8e';
}
.icon-fdz:before {
    content: '\ea8f';
}
.icon-fil:before {
    content: '\ea90';
}
.icon-fish:before {
    content: '\ea91';
}
.icon-flc:before {
    content: '\ea92';
}
.icon-fldc:before {
    content: '\ea93';
}
.icon-flo:before {
    content: '\ea94';
}
.icon-flot:before {
    content: '\ea95';
}
.icon-flx:before {
    content: '\ea96';
}
.icon-food:before {
    content: '\ea97';
}
.icon-fsn:before {
    content: '\ea98';
}
.icon-ftc:before {
    content: '\ea99';
}
.icon-fuel:before {
    content: '\ea9a';
}
.icon-fun:before {
    content: '\ea9b';
}
.icon-game:before {
    content: '\ea9c';
}
.icon-gas:before {
    content: '\ea9d';
}
.icon-gbx:before {
    content: '\ea9e';
}
.icon-gbyte:before {
    content: '\ea9f';
}
.icon-gbytr-alt:before {
    content: '\eaa0';
}
.icon-gcr:before {
    content: '\eaa1';
}
.icon-gcs:before {
    content: '\eaa2';
}
.icon-gene:before {
    content: '\eaa3';
}
.icon-generic:before {
    content: '\eaa4';
}
.icon-getx:before {
    content: '\eaa5';
}
.icon-gld-alt:before {
    content: '\eaa6';
}
.icon-gnct:before {
    content: '\eaa7';
}
.icon-gno:before {
    content: '\eaa8';
}
.icon-gnt:before {
    content: '\eaa9';
}
.icon-gpt:before {
    content: '\eaaa';
}
.icon-grc:before {
    content: '\eaab';
}
.icon-grs:before {
    content: '\eaac';
}
.icon-grx:before {
    content: '\eaad';
}
.icon-gsc:before {
    content: '\eaae';
}
.icon-gto:before {
    content: '\eaaf';
}
.icon-gup:before {
    content: '\eab0';
}
.icon-gvt:before {
    content: '\eab1';
}
.icon-gxs:before {
    content: '\eab2';
}
.icon-hav:before {
    content: '\eab3';
}
.icon-hero:before {
    content: '\eab4';
}
.icon-hky:before {
    content: '\eab5';
}
.icon-hmc:before {
    content: '\eab6';
}
.icon-hot:before {
    content: '\eab7';
}
.icon-hpb:before {
    content: '\eab8';
}
.icon-hqx:before {
    content: '\eab9';
}
.icon-hsr:before {
    content: '\eaba';
}
.icon-hst:before {
    content: '\eabb';
}
.icon-html:before {
    content: '\eabc';
}
.icon-huc:before {
    content: '\eabd';
}
.icon-hur:before {
    content: '\eabe';
}
.icon-hush:before {
    content: '\eabf';
}
.icon-icc:before {
    content: '\eac0';
}
.icon-icn:before {
    content: '\eac1';
}
.icon-icst:before {
    content: '\eac2';
}
.icon-icx:before {
    content: '\eac3';
}
.icon-idh:before {
    content: '\eac4';
}
.icon-ift:before {
    content: '\eac5';
}
.icon-ignis:before {
    content: '\eac6';
}
.icon-ignt:before {
    content: '\eac7';
}
.icon-ink:before {
    content: '\eac8';
}
.icon-ins:before {
    content: '\eac9';
}
.icon-instar:before {
    content: '\eaca';
}
.icon-ion:before {
    content: '\eacb';
}
.icon-iop:before {
    content: '\eacc';
}
.icon-iost:before {
    content: '\eacd';
}
.icon-itc:before {
    content: '\eace';
}
.icon-iwc:before {
    content: '\eacf';
}
.icon-izx:before {
    content: '\ead0';
}
.icon-j8t:before {
    content: '\ead1';
}
.icon-jnt:before {
    content: '\ead2';
}
.icon-jpy:before {
    content: '\ead3';
}
.icon-kbc:before {
    content: '\ead4';
}
.icon-kcs:before {
    content: '\ead5';
}
.icon-kick:before {
    content: '\ead6';
}
.icon-kin:before {
    content: '\ead7';
}
.icon-kmd:before {
    content: '\ead8';
}
.icon-knc:before {
    content: '\ead9';
}
.icon-knw:before {
    content: '\eada';
}
.icon-krb:before {
    content: '\eadb';
}
.icon-kri:before {
    content: '\eadc';
}
.icon-kyc:before {
    content: '\eadd';
}
.icon-lbc:before {
    content: '\eade';
}
.icon-lcd:before {
    content: '\eadf';
}
.icon-ldn:before {
    content: '\eae0';
}
.icon-lend:before {
    content: '\eae1';
}
.icon-leo:before {
    content: '\eae2';
}
.icon-lhc:before {
    content: '\eae3';
}
.icon-link:before {
    content: '\eae4';
}
.icon-lkk:before {
    content: '\eae5';
}
.icon-lnc:before {
    content: '\eae6';
}
.icon-loc:before {
    content: '\eae7';
}
.icon-lot:before {
    content: '\eae8';
}
.icon-lrc:before {
    content: '\eae9';
}
.icon-lsk:before {
    content: '\eaea';
}
.icon-lst:before {
    content: '\eaeb';
}
.icon-ltc:before {
    content: '\eaec';
}
.icon-lun:before {
    content: '\eaed';
}
.icon-lynda:before {
    content: '\eaee';
}
.icon-maid:before {
    content: '\eaef';
}
.icon-mana:before {
    content: '\eaf0';
}
.icon-mcap:before {
    content: '\eaf1';
}
.icon-mco:before {
    content: '\eaf2';
}
.icon-mda:before {
    content: '\eaf3';
}
.icon-mdk:before {
    content: '\eaf4';
}
.icon-mdr:before {
    content: '\eaf5';
}
.icon-mds:before {
    content: '\eaf6';
}
.icon-med:before {
    content: '\eaf7';
}
.icon-mesh:before {
    content: '\eaf8';
}
.icon-miota:before {
    content: '\eaf9';
}
.icon-mkr:before {
    content: '\eafa';
}
.icon-mln:before {
    content: '\eafb';
}
.icon-mnx:before {
    content: '\eafc';
}
.icon-mod:before {
    content: '\eafd';
}
.icon-mona:before {
    content: '\eafe';
}
.icon-mrk:before {
    content: '\eaff';
}
.icon-mrl:before {
    content: '\eb00';
}
.icon-mth:before {
    content: '\eb01';
}
.icon-mtl:before {
    content: '\eb02';
}
.icon-mue:before {
    content: '\eb03';
}
.icon-music:before {
    content: '\eb04';
}
.icon-mvr:before {
    content: '\eb05';
}
.icon-mzc:before {
    content: '\eb06';
}
.icon-nanj:before {
    content: '\eb07';
}
.icon-nano:before {
    content: '\eb08';
}
.icon-nas:before {
    content: '\eb09';
}
.icon-nav:before {
    content: '\eb0a';
}
.icon-ncash:before {
    content: '\eb0b';
}
.icon-ndz:before {
    content: '\eb0c';
}
.icon-neal:before {
    content: '\eb0d';
}
.icon-nebl:before {
    content: '\eb0e';
}
.icon-neo:before {
    content: '\eb0f';
}
.icon-neos:before {
    content: '\eb10';
}
.icon-ngc:before {
    content: '\eb11';
}
.icon-nlc2:before {
    content: '\eb12';
}
.icon-nlg:before {
    content: '\eb13';
}
.icon-nmc:before {
    content: '\eb14';
}
.icon-nmr:before {
    content: '\eb15';
}
.icon-nox:before {
    content: '\eb16';
}
.icon-nuls:before {
    content: '\eb17';
}
.icon-nvst:before {
    content: '\eb18';
}
.icon-nxs:before {
    content: '\eb19';
}
.icon-nxt:before {
    content: '\eb1a';
}
.icon-oak:before {
    content: '\eb1b';
}
.icon-oax:before {
    content: '\eb1c';
}
.icon-occ:before {
    content: '\eb1d';
}
.icon-ocn:before {
    content: '\eb1e';
}
.icon-odem:before {
    content: '\eb1f';
}
.icon-omg:before {
    content: '\eb20';
}
.icon-omni:before {
    content: '\eb21';
}
.icon-ont:before {
    content: '\eb22';
}
.icon-onx:before {
    content: '\eb23';
}
.icon-ost:before {
    content: '\eb24';
}
.icon-otn:before {
    content: '\eb25';
}
.icon-ox:before {
    content: '\eb26';
}
.icon-part:before {
    content: '\eb27';
}
.icon-pasl:before {
    content: '\eb28';
}
.icon-pay:before {
    content: '\eb29';
}
.icon-pef:before {
    content: '\eb2a';
}
.icon-petro:before {
    content: '\eb2b';
}
.icon-pew:before {
    content: '\eb2c';
}
.icon-pgt:before {
    content: '\eb2d';
}
.icon-phi:before {
    content: '\eb2e';
}
.icon-pink:before {
    content: '\eb2f';
}
.icon-pirl:before {
    content: '\eb30';
}
.icon-pivx:before {
    content: '\eb31';
}
.icon-pix:before {
    content: '\eb32';
}
.icon-plr:before {
    content: '\eb33';
}
.icon-pm7:before {
    content: '\eb34';
}
.icon-pmnt:before {
    content: '\eb35';
}
.icon-poa:before {
    content: '\eb36';
}
.icon-poe:before {
    content: '\eb37';
}
.icon-poly:before {
    content: '\eb38';
}
.icon-polyai:before {
    content: '\eb39';
}
.icon-posw:before {
    content: '\eb3a';
}
.icon-pot:before {
    content: '\eb3b';
}
.icon-powr:before {
    content: '\eb3c';
}
.icon-ppc:before {
    content: '\eb3d';
}
.icon-ppp:before {
    content: '\eb3e';
}
.icon-ppt:before {
    content: '\eb3f';
}
.icon-prl:before {
    content: '\eb40';
}
.icon-pro:before {
    content: '\eb41';
}
.icon-proc:before {
    content: '\eb42';
}
.icon-pura:before {
    content: '\eb43';
}
.icon-pxc:before {
    content: '\eb44';
}
.icon-qash:before {
    content: '\eb45';
}
.icon-qau:before {
    content: '\eb46';
}
.icon-qiwi:before {
    content: '\eb47';
}
.icon-qlc:before {
    content: '\eb48';
}
.icon-qrk:before {
    content: '\eb49';
}
.icon-qrl:before {
    content: '\eb4a';
}
.icon-qsp:before {
    content: '\eb4b';
}
.icon-qtum:before {
    content: '\eb4c';
}
.icon-qwark:before {
    content: '\eb4d';
}
.icon-r:before {
    content: '\eb4e';
}
.icon-rads:before {
    content: '\eb4f';
}
.icon-ramen:before {
    content: '\eb50';
}
.icon-rblx:before {
    content: '\eb51';
}
.icon-rcn:before {
    content: '\eb52';
}
.icon-rdd:before {
    content: '\eb53';
}
.icon-rdn:before {
    content: '\eb54';
}
.icon-rebl:before {
    content: '\eb55';
}
.icon-ref:before {
    content: '\eb56';
}
.icon-rep:before {
    content: '\eb57';
}
.icon-req:before {
    content: '\eb58';
}
.icon-rfr:before {
    content: '\eb59';
}
.icon-rhoc:before {
    content: '\eb5a';
}
.icon-ric:before {
    content: '\eb5b';
}
.icon-rise:before {
    content: '\eb5c';
}
.icon-rkt:before {
    content: '\eb5d';
}
.icon-rlc:before {
    content: '\eb5e';
}
.icon-rnt:before {
    content: '\eb5f';
}
.icon-rock:before {
    content: '\eb60';
}
.icon-rock2:before {
    content: '\eb61';
}
.icon-rpx:before {
    content: '\eb62';
}
.icon-rub:before {
    content: '\eb63';
}
.icon-rvn:before {
    content: '\eb64';
}
.icon-rvr:before {
    content: '\eb65';
}
.icon-salt:before {
    content: '\eb66';
}
.icon-sam:before {
    content: '\eb67';
}
.icon-san:before {
    content: '\eb68';
}
.icon-sat:before {
    content: '\eb69';
}
.icon-sbd:before {
    content: '\eb6a';
}
.icon-sberbank:before {
    content: '\eb6b';
}
.icon-sc:before {
    content: '\eb6c';
}
.icon-scc:before {
    content: '\eb6d';
}
.icon-seth:before {
    content: '\eb6e';
}
.icon-sexc:before {
    content: '\eb6f';
}
.icon-ship:before {
    content: '\eb70';
}
.icon-shl:before {
    content: '\eb71';
}
.icon-sib:before {
    content: '\eb72';
}
.icon-sig:before {
    content: '\eb73';
}
.icon-skrp:before {
    content: '\eb74';
}
.icon-sky:before {
    content: '\eb75';
}
.icon-slr:before {
    content: '\eb76';
}
.icon-sls:before {
    content: '\eb77';
}
.icon-smart:before {
    content: '\eb78';
}
.icon-sngls:before {
    content: '\eb79';
}
.icon-snm:before {
    content: '\eb7a';
}
.icon-snt:before {
    content: '\eb7b';
}
.icon-spank:before {
    content: '\eb7c';
}
.icon-sparc:before {
    content: '\eb7d';
}
.icon-sphtx:before {
    content: '\eb7e';
}
.icon-spx:before {
    content: '\eb7f';
}
.icon-srn:before {
    content: '\eb80';
}
.icon-srnt:before {
    content: '\eb81';
}
.icon-start:before {
    content: '\eb82';
}
.icon-ste:before {
    content: '\eb83';
}
.icon-steem:before {
    content: '\eb84';
}
.icon-ster:before {
    content: '\eb85';
}
.icon-storj:before {
    content: '\eb86';
}
.icon-storm:before {
    content: '\eb87';
}
.icon-stq:before {
    content: '\eb88';
}
.icon-strat:before {
    content: '\eb89';
}
.icon-stv:before {
    content: '\eb8a';
}
.icon-sub:before {
    content: '\eb8b';
}
.icon-sys:before {
    content: '\eb8c';
}
.icon-taas:before {
    content: '\eb8d';
}
.icon-tau:before {
    content: '\eb8e';
}
.icon-team:before {
    content: '\eb8f';
}
.icon-tel:before {
    content: '\eb90';
}
.icon-theta:before {
    content: '\eb91';
}
.icon-tio:before {
    content: '\eb92';
}
.icon-tix:before {
    content: '\eb93';
}
.icon-tkn:before {
    content: '\eb94';
}
.icon-tkt:before {
    content: '\eb95';
}
.icon-tnb:before {
    content: '\eb96';
}
.icon-tnc:before {
    content: '\eb97';
}
.icon-tnt:before {
    content: '\eb98';
}
.icon-trak:before {
    content: '\eb99';
}
.icon-trg:before {
    content: '\eb9a';
}
.icon-trig:before {
    content: '\eb9b';
}
.icon-trst:before {
    content: '\eb9c';
}
.icon-trx:before {
    content: '\eb9d';
}
.icon-tzc:before {
    content: '\eb9e';
}
.icon-ubq:before {
    content: '\eb9f';
}
.icon-ucash:before {
    content: '\eba0';
}
.icon-uct:before {
    content: '\eba1';
}
.icon-uip:before {
    content: '\eba2';
}
.icon-ukg:before {
    content: '\eba3';
}
.icon-unity:before {
    content: '\eba4';
}
.icon-unv:before {
    content: '\eba5';
}
.icon-usat:before {
    content: '\eba6';
}
.icon-ust:before {
    content: '\eba8';
}
.icon-utk:before {
    content: '\eba9';
}
.icon-utnp:before {
    content: '\ebaa';
}
.icon-ven:before {
    content: '\ebab';
}
.icon-veri:before {
    content: '\ebac';
}
.icon-via:before {
    content: '\ebad';
}
.icon-vib:before {
    content: '\ebae';
}
.icon-vibe:before {
    content: '\ebaf';
}
.icon-vico:before {
    content: '\ebb0';
}
.icon-vivo:before {
    content: '\ebb1';
}
.icon-vrc:before {
    content: '\ebb2';
}
.icon-vtc:before {
    content: '\ebb3';
}
.icon-wabi:before {
    content: '\ebb4';
}
.icon-wan:before {
    content: '\ebb5';
}
.icon-waves:before {
    content: '\ebb6';
}
.icon-wax:before {
    content: '\ebb7';
}
.icon-wgr:before {
    content: '\ebb8';
}
.icon-wings:before {
    content: '\ebb9';
}
.icon-wpr:before {
    content: '\ebba';
}
.icon-wrc:before {
    content: '\ebbb';
}
.icon-wtc:before {
    content: '\ebbc';
}
.icon-wts:before {
    content: '\ebbd';
}
.icon-xas:before {
    content: '\ebbe';
}
.icon-xaur:before {
    content: '\ebbf';
}
.icon-xbc:before {
    content: '\ebc0';
}
.icon-xbp:before {
    content: '\ebc1';
}
.icon-xby:before {
    content: '\ebc2';
}
.icon-xcp:before {
    content: '\ebc3';
}
.icon-xdac:before {
    content: '\ebc4';
}
.icon-xdn:before {
    content: '\ebc5';
}
.icon-xem:before {
    content: '\ebc6';
}
.icon-xlm:before {
    content: '\ebc7';
}
.icon-xmg:before {
    content: '\ebc8';
}
.icon-xmr:before {
    content: '\ebc9';
}
.icon-xmy:before {
    content: '\ebca';
}
.icon-xnn:before {
    content: '\ebcb';
}
.icon-xp:before {
    content: '\ebcc';
}
.icon-xpa:before {
    content: '\ebcd';
}
.icon-xpm:before {
    content: '\ebce';
}
.icon-xpr:before {
    content: '\ebcf';
}
.icon-xrp:before {
    content: '\ebd0';
}
.icon-xrr:before {
    content: '\ebd1';
}
.icon-xtz:before {
    content: '\ebd2';
}
.icon-xuc:before {
    content: '\ebd3';
}
.icon-xvc:before {
    content: '\ebd4';
}
.icon-xvg:before {
    content: '\ebd5';
}
.icon-xyo:before {
    content: '\ebd6';
}
.icon-xzc:before {
    content: '\ebd7';
}
.icon-yoyow:before {
    content: '\ebd8';
}
.icon-zcl:before {
    content: '\ebd9';
}
.icon-zdr:before {
    content: '\ebda';
}
.icon-zec:before {
    content: '\ebdb';
}
.icon-zeew:before {
    content: '\ebdc';
}
.icon-zen:before {
    content: '\ebdd';
}
.icon-zil:before {
    content: '\ebde';
}
.icon-zla:before {
    content: '\ebdf';
}
.icon-zrx:before {
    content: '\ebe0';
}
.icon-aud:before {
    content: '\ebe1';
}
.icon-cad:before {
    content: '\ebe2';
}
.icon-chf:before {
    content: '\ebe3';
}
.icon-cny2:before {
    content: '\ebe4';
}
.icon-eur:before {
    content: '\ebe5';
}
.icon-gbp:before {
    content: '\ebe6';
}
.icon-idr:before {
    content: '\ebe7';
}
.icon-myr:before {
    content: '\ebe8';
}
.icon-rur:before {
    content: '\ebe9';
}
.icon-thb:before {
    content: '\ebea';
}
.icon-try:before {
    content: '\ebeb';
}
.icon-usd:before {
    content: '\ebed';
}
.icon-vnd:before {
    content: '\ebef';
}
.icon-zar:before {
    content: '\ebee';
}
.icon-yupp:before {
    content: '\e900';
}
.icon-btcy:before {
    content: '\ebf0';
}
.icon-usdt:before {
    content: '\ebf4';
}
.icon-tety:before {
    content: '\eba7';
}
.icon-bchy:before {
    content: '\ebf1';
}
.icon-ethy:before {
    content: '\ebf2';
}
.icon-xrpy:before {
    content: '\ebf3';
}
.icon-eury:before {
    content: '\ebf5';
}
.icon-usdy:before {
    content: '\ebec';
}
